<template>
  <div v-loading="!dataLoaded" style="margin: 10px; width: calc(100vw - 85px)">
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="isOpenAlertForm"
      :title="isNewAlert?$t('settings.alert_add'):$t('settings.alert_edit')"
      width="730px"
    >
      <span v-if="showWarningMessage" style="color: #ff0022"><i class="fas fa-exclamation-triangle"></i> {{ $t('settings.alert_warning_type') }}</span>
      <el-form ref="alert" :model="alertForm" :rules="rules">
        <el-form-item :label="$t('Nome')+':'">
          <el-input v-model="name" />
        </el-form-item>
        <div class="form-item-block">
          <div class="form-item-row">
            <el-form-item style="display: table-cell" :label="$t('settings.alerts_type')" prop="selectedType">
              <el-select v-if="isNewAlert" v-model="alertForm.selectedType" value="" :placeholder="$t('settings.alert_form_type_placeholder')">
                <el-option v-for="alertType in alertTypes" :key="alertType.value" :value="alertType.value" :label="alertType.text" />
              </el-select>
              <span v-else class="alertTypeTitle">{{
                $t('settings.alert_'+alertForm.selectedType).startsWith('settings.alert_') ?
                  alertForm.selectedType :
                  $t('settings.alert_'+alertForm.selectedType)
              }}</span>
              <el-tooltip v-if="showInfoMessage" placement="bottom" effect="light">
                <div slot="content">{{ $t('settings.alert_'+alertForm.selectedType) }}</div>
                <i class="fas fa-info-circle alertInfo"></i>
              </el-tooltip>
            </el-form-item>
            <el-form-item v-if="alertForm.selectedType !== 'inactivity'" style="display: table-cell" :label="$t('settings.alerts_notificators')+':'" prop="notificatorsGroup">
              <el-checkbox-group v-model="alertForm.notificatorsGroup">
                <el-checkbox-button label="web">{{ $t('settings.alert_form_notificator_web') }}</el-checkbox-button>
                <el-checkbox-button label="mail">{{ $t('settings.alert_form_notificator_email') }}</el-checkbox-button>
                <el-checkbox-button label="sms">{{ $t('settings.alert_form_notificator_sms') }}</el-checkbox-button>
                <el-checkbox-button label="whatsapp">{{ $t('Whatsapp') }}</el-checkbox-button>
                <el-checkbox-button label="driver">{{ $t('settings.driver') }}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </div>
        </div>
        <div v-if="alertForm.selectedType === 'inactivity'">
          <div class="inactivityAlertInfo">
            <span>{{ $t('profile.inactive_vehicles_email_tooltip') }}</span>
          </div>
          <el-form>
            <el-form-item :label="$t('settings.other_emails')+':'">
              <el-input v-model="otherEmails" style="width: 400px" />
            </el-form-item>
          </el-form>
        </div>
        <el-tabs
          v-if="alertForm.selectedType !== 'inactivity'"
          active-tab-color="#9b59b6"
          active-text-color="white"
          stretch
          style="padding: 5px"
          @tab-click="handleTabClick"
        >
          <el-tab-pane>
            <span v-if="byDevice" slot="label">
              <i class="fas fa-car"></i><span style="margin-left: 10px">{{ $t('settings.vehicles') }}</span>
            </span>
            <span v-else slot="label">
              <i class="fas fa-user"></i><span style="margin-left: 10px">{{ $t('settings.drivers') }}</span>
            </span>
            <div>
              <el-form-item style="margin-bottom: 0">
                <el-checkbox-group v-model="allVehicles" @change="handleAllVehiclesSelect">
                  <el-checkbox key="always" label="always">{{ byDevice? $t('settings.alert_form_all_vehicles') : $t('vehicleTable.all_vehicles') }}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <div v-if="allVehicles.length!==1">
                <el-transfer
                  v-if="byDevice"
                  v-model="selectedDevices"
                  :filter-method="filteredDevices"
                  filterable
                  :filter-placeholder="$t('settings.search')"
                  :titles="[$t('settings.vehicles'), $t('settings.transfer_selected')]"
                  :props="{
                    key: 'id',
                    label: 'name'
                  }"
                  :data="devices"
                  :render-content="renderFunc"
                >
                </el-transfer>
                <el-transfer
                  v-else
                  v-model="selectedDrivers"
                  :filter-method="filteredDevices"
                  filterable
                  :filter-placeholder="$t('settings.search')"
                  :titles="[$t('settings.drivers'), $t('settings.transfer_selected')]"
                  :props="{
                    key: 'id',
                    label: 'name'
                  }"
                  :data="drivers"
                  :render-content="renderFunc"
                >
                </el-transfer>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane v-if="alertForm.selectedType === 'geofenceEnter' || alertForm.selectedType === 'geofenceExit'" name="geofences">
            <span slot="label">
              <i class="fas fa-map-marked"></i>
            </span>
            <el-form-item>
              <el-transfer
                v-model="selectedGeofences"
                class="transfer-user"
                filterable
                :filter-placeholder="$t('report.selector_search')"
                :titles="[$t('settings.zone'), $t('report.select_groups')]"
                :props="{
                  key: 'id',
                  label: 'name'
                }"
                :data="renderedGeofences"
              >
              </el-transfer>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label">
              <i class="fas fa-clock"></i><span style="margin-left: 10px">{{ $t('settings.time') }}</span>
            </span>
            <div>
              <el-form-item>
                <time-table v-model="timetable"></time-table>
              </el-form-item>
            </div>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label">
              <i class="fas fa-cog"></i><span style="margin-left: 10px">{{ $t('settings.configuration') }}</span>
            </span>
            <div>
              <el-row>
                <el-col :span="8">
                  <el-form-item v-if="alertForm.selectedType === 'sensor'" :label="$t('Sensores')">
                    <el-select v-model="selectedSensors" multiple placeholder="Select">
                      <el-option
                        v-for="item in getDistinctSensors()"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item v-if="showDuration" :label="alertForm.selectedType === 'alarmWeeklyDriving' ? $t('Hours') : $t('settings.alert_form_ignitionoff_max_duration')">
                    <el-input-number v-model="maxDuration" :min="0" :precision="0" />
                  </el-form-item>
                </el-col>
              </el-row>
              <div style="padding: 10px"></div>
              <el-radio-group v-model="checkGeofences">
                <el-radio label="insideOutside">{{ $t('Inside or outside zones') }}</el-radio>
                <el-radio label="onlyInside">{{ $t('Only inside zones') }}</el-radio>
                <el-radio label="onlyOutside">{{ $t('Only oustside zones') }}</el-radio>
              </el-radio-group>
              <br>
              <el-switch v-if="partnerData.partnerId === 14" v-model="integration" :active-text="$t('Integración con central de alarmas')" style="padding-top: 10px"></el-switch>
            </div>
          </el-tab-pane>
          <el-tab-pane v-if="alertForm.notificatorsGroup.includes('mail')">
            <span slot="label">
              <i class="fas fa-envelope"></i><span style="margin-left: 10px">{{ $t('settings.emails') }}</span>
            </span>
            <div>
              <el-form-item v-if="alertForm.notificatorsGroup.includes('mail')" :label="$t('settings.other_emails')+':'">
                <el-input v-model="otherEmails" />
              </el-form-item>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <div slot="footer">
        <el-button
          type="info"
          size="small"
          @click="handleCancelAlertForm"
        >{{ $t('settings.alert_form_cancel') }}</el-button>
        <el-button
          :loading="loading"
          type="success"
          size="small"
          @click="handleSubmitAlertForm"
        >{{ $t('settings.alert_form_confirm') }}</el-button>
      </div>
    </el-dialog>
    <el-table
      :key="alertTableKey"
      :height="tableHeight"
      :data="alerts"
      :row-style="tableRowStyle"
      :header-cell-style="tableHeaderStyle"
      @row-dblclick="handleDoubleClick"
    >
      <el-table-column
        :min-width="20"
        type="expand"
      >
        <template slot-scope="props">
          <el-table
            highlight-current-row
            :data="props.row.devices"
            :show-header="false"
            cell-class-name="cellInfo"
          >
            <el-table-column
              v-if="isInorOutGeofence(props.row)"
              prop="id"
              sortable=""
              width="50"
            >
              <template slot-scope="scope">
                <el-tooltip :content="$t('settings.alert_geofences_warning')" placement="top">
                  <i v-if="scope.row.geofences.length === 0" class="fas fa-exclamation-triangle"></i></el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              v-if="isDeviceOverspeed(props.row)"
              prop="attributes.speedLimit"
              sortable=""
              width="50"
            >
              <template slot-scope="scope">
                <el-tooltip :content="$t('settings.alert_overspeed_warning')" placement="top">
                  <i v-if="scope.row.attributes.speedLimit === 0 || !scope.row.attributes.speedLimit" class="fas fa-exclamation-triangle"></i></el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="Vehicles"
              sortable=""
            >
            </el-table-column>
            <el-table-column
              v-if="isDeviceOverspeed(props.row)"
              prop="attributes.speedLimit"
              :formatter="alertSpeedRenderer"
              sortable=""
            >
            </el-table-column>
            <el-table-column
              v-if="isDeviceFuelDrop(props.row)"
              prop="attributes.fuelDropThreshold"
              :formatter="fuelDropThreasholdRenderer"
              sortable=""
            >
            </el-table-column>
            <el-table-column
              v-if="isInorOutGeofence(props.row)"
              prop="geofences"
              sortable=""
            >
              <template slot-scope="scope">
                <span v-html="vehicleGeofences(scope.row)"></span>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        :min-width="50"
        :label="$t('Nome')"
        prop="notification.attributes.name"
      />
      <el-table-column
        :min-width="50"
        :label="$t('settings.alerts_type')"
        :formatter="alertTypeRenderer"
        prop="notification"
      >
      </el-table-column>
      <el-table-column
        :min-width="20"
        :label="$t('settings.alerts_notificators')"
        prop="notification.notificators"
      >
      </el-table-column>
      <el-table-column
        :min-width="20"
        :label="$t('Veículos')"
        prop="devices"
        :formatter="devicesRenderer"
      >
      </el-table-column>
      <el-table-column
        v-if="partnerData.partnerId === 14"
        :label="$t('Integración')"
        :min-width="22"
        prop="notification.attributes.integration"
      ><template v-slot="scope">
        {{ scope.row.notification.attributes.integration || '' }}
      </template>
      </el-table-column>
      <el-table-column
        :min-width="20"
        label=""
        align="right"
      >
        <template slot="header">
          <el-tooltip :content="$t('settings.add_alert')" placement="top">
            <el-button
              class="alertFormButton"
              size="small"
              @click="handleAddAlert"
            ><i class="fas fa-plus"></i></el-button>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-tooltip :content="$t('settings.alert_edit')" placement="top">
            <el-button
              size="small"
              @click="handleEdit(scope.row)"
            ><i class="fas fa-edit"></i></el-button>
          </el-tooltip>
          <el-tooltip :content="$t('settings.alert_delete')" placement="top">
            <el-button
              size="small"
              type="danger"
              @click="handleDelete(scope.row)"
            ><i class="fas fa-trash-alt"></i></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total"
      :total="alerts.length"
    >
    </el-pagination>
  </div>
</template>

<script>
import { serverBus, vm } from '@/main'
import { traccar } from '@/api/traccar-api'
import * as alertType from '../../../alerts/alertType'
import { mapGetters } from 'vuex'
import TimeTable from '@/components/TimeTable'
import { getPartnerData } from 'fleetmap-partners'
import { pinmeapi } from '@/api/pinme'

function getComputed(deviceId, newAlert) {
  if (newAlert.type === 'alarm' && newAlert.attributes) {
    switch (newAlert.attributes.alarms) {
      case alertType.alarmIdle:
        return { deviceId, attributeId: alertType.idleAlarmComputedId }
      case alertType.alarmTow:
        return { deviceId, attributeId: alertType.towAlarmComputedId }
      case alertType.alarmUnidentifiedDriver:
        return { deviceId, attributeId: alertType.unidentifiedDriverAlarmComputedId }
      case 'alarmEngineOn':
        return { deviceId, attributeId: 78 }
      case 'alarmEngineOff':
        return { deviceId, attributeId: 97 }
    }
  }
  return null
}

export default {
  name: 'Alerts',
  components: { TimeTable },
  data() {
    return {
      name: '',
      tabName: '',
      tableHeight: 'calc(100vh - 100px)',
      alertTableKey: 0,
      isOpenAlertForm: false,
      isNewAlert: true,
      selectedAlert: null,
      alertForm: {
        selectedType: null,
        notificatorsGroup: []
      },
      allVehicles: [],
      selectedDevices: [],
      selectedDrivers: [],
      renderedGeofences: [],
      selectedGeofences: [],
      selectedDevice: null,
      maxDuration: 0,
      selectedSensors: [],
      integration: false,
      checkGeofences: 'insideOutside',
      otherEmails: '',
      timetable: {
        allWeek: true,
        startTime: '00:00',
        endTime: '23:59',
        weekDays: {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true
        }
      },
      loading: false,
      rules: {
        notificatorsGroup: [
          { type: 'array', required: true, message: this.$t('settings.alert_form_notificator_select_on'), trigger: 'change' }
        ],
        selectedType: [
          { required: true, message: this.$t('settings.alert_form_notificator_select_on'), trigger: 'change' }
        ]
      },
      alertTypes: [
        { value: alertType.alarmEngineOff, text: this.$t('settings.alert_alarmEngineOff') },
        { value: alertType.alarmEngineOn, text: this.$t('settings.alert_alarmEngineOn') },
        { value: alertType.alarmSensor, text: this.$t('settings.alert_sensor') },
        { value: alertType.alarmIdle, text: this.$t('settings.alert_idle') },
        { value: alertType.geofenceExit, text: this.$t('settings.alert_geofenceExit') },
        { value: alertType.geofenceEnter, text: this.$t('settings.alert_geofenceEnter') },
        { value: alertType.deviceOverspeed, text: this.$t('settings.alert_deviceOverspeed') },
        { value: alertType.ignitionOn, text: this.$t('settings.alert_ignitionOn') },
        { value: alertType.ignitionOff, text: this.$t('settings.alert_ignitionOff') },
        { value: alertType.deviceFuelDrop, text: this.$t('settings.alert_deviceFuelDrop') },
        { value: alertType.driverChanged, text: this.$t('settings.alert_driverChanged') },
        { value: alertType.maintenance, text: this.$t('settings.alert_maintenance') },
        { value: alertType.alarmSOS, text: this.$t('settings.alert_sos') },
        { value: alertType.alarmPowerCut, text: this.$t('settings.alert_powerCut') },
        { value: alertType.alarmTow, text: this.$t('settings.alert_tow') },
        { value: alertType.alarmGPSAntennaCut, text: this.$t('settings.alert_gpsAntennaCut') },
        { value: alertType.alarmHighRpm, text: this.$t('settings.alert_highRpm') },
        { value: alertType.alarmHardAcceleration, text: this.$t('settings.alert_hardAcceleration') },
        { value: alertType.alarmHardBraking, text: this.$t('settings.alert_hardBraking') },
        { value: alertType.alarmHardCornering, text: this.$t('settings.alert_hardCornering') },
        { value: alertType.alarmTemperature, text: this.$t('settings.alert_temperature') },
        { value: alertType.alarmUnidentifiedDriver, text: this.$t('settings.alert_unidentifiedDriver') },
        { value: alertType.alarmWeeklyDriving, text: this.$t('Condução Semanal') },
        { value: alertType.inactivity, text: this.$t('settings.alert_inactivity') }
      ].sort((e1, e2) => { return e1.text.localeCompare(e2.text) }),
      filteredDevices(query, item) {
        return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1 || (item.license_plate && item.license_plate.toLowerCase().indexOf(query.toLowerCase()) > -1)
      }
    }
  },
  computed: {
    ...mapGetters(['devices', 'dataLoaded', 'user', 'alerts', 'geofences', 'groups']),
    byDevice() {
      return this.alertForm.selectedType !== alertType.alarmWeeklyDriving
    },
    partnerData() {
      return getPartnerData()
    },
    devices: function() {
      return vm.$store.getters.devices.slice().sort((a, b) => (a.name > b.name) ? 1 : -1)
    },
    drivers: function() {
      return vm.$store.getters.drivers.slice().sort((a, b) => (a.name > b.name) ? 1 : -1)
    },
    showWarningMessage: function() {
      return alertType.unitAlarmTypes.includes(this.alertForm.selectedType)
    },
    showInfoMessage: function() {
      return this.alertForm.selectedType != null
    },
    showDuration() {
      switch (this.alertForm.selectedType) {
        case alertType.ignitionOn:
        case alertType.ignitionOff:
        case alertType.alarmIdle:
          return false
        default:
          return true
      }
    }
  },
  created() {
    serverBus.$on('driversClicked', (e) => {
      if (e.index === '6') {
        console.log(e)
        setTimeout(() => {
          this.tableHeight = 'calc(100vh - 270px)'
          setTimeout(() => {
            this.tableHeight = 'calc(100vh - 170px)'
          }, 500)
        }, 500)
      }
    })
  },
  mounted() {
    this.$store.dispatch('user/fetchAlerts')
  },
  methods: {
    isDeviceOverspeed: function(row) {
      return row.notification.type === alertType.deviceOverspeed
    },
    isDeviceFuelDrop: function(row) {
      return row.notification.type === alertType.deviceFuelDrop
    },
    isInorOutGeofence: function(row) {
      return row.notification.type === alertType.geofenceExit || row.notification.type === alertType.geofenceEnter
    },
    alertTypeRenderer(row, column, notification) {
      if (notification.type !== 'alarm') {
        return this.$t('settings.alert_' + notification.type)
      } else {
        return this.$t('settings.alert_' + notification.attributes.alarms) !== 'settings.alert_' + notification.attributes.alarms
          ? this.$t('settings.alert_' + notification.attributes.alarms)
          : notification.attributes.alarms
      }
    },
    alertSpeedRenderer(row, column, cellValue) {
      if (cellValue) {
        return Math.round(cellValue * 1.85200) + ' Km/h'
      } else {
        return ''
      }
    },
    fuelDropThreasholdRenderer(row, column, cellValue) {
      if (cellValue) {
        return cellValue + '%'
      } else {
        return ''
      }
    },
    devicesRenderer(row, column, cellValue) {
      if (row.notification.always) {
        return this.$t('report.select_all')
      } else {
        return cellValue.length
      }
    },
    handleAddAlert() {
      this.isNewAlert = true
      this.isOpenAlertForm = !this.isOpenAlertForm
    },
    async handleTabClick(tab) {
      this.tabName = tab.name
      // geofences
      if (tab.name === 'geofences') {
        this.loading = true
        setTimeout(() => {
          this.renderedGeofences = this.geofences.map(g => ({ id: g.id, name: g.name }))
          this.loading = false
        }, 100)
      } else {
        this.renderedGeofences = []
      }
    },
    handleCancelAlertForm() {
      this.isOpenAlertForm = false
      this.clearFormData()
    },
    getDistinctSensors() {
      return this.devices
        .filter(d => d.attributes.sensor1 || d.attributes.sensor2 || d.attributes.sensor3)
        .map(d => [d.attributes.sensor1, d.attributes.sensor2, d.attributes.sensor3])
        .flat()
        .filter(d => d)
        // remove duplicates
        .filter((value, index, array) => array.indexOf(value) === index)
        .sort()
    },
    fillAlert: function(alert) {
      alert.always = this.allVehicles.length === 1
      alert.notificators = this.alertForm.notificatorsGroup.toString()
      alert.attributes.maxDuration = this.maxDuration
      alert.attributes.otherEmails = this.otherEmails
      alert.attributes.name = this.name
      alert.attributes.timetable = this.timetable
      alert.attributes.checkGeofences = this.checkGeofences
      alert.attributes.geofences = this.selectedGeofences
      alert.attributes.drivers = this.selectedDrivers
      if (this.alertForm.selectedType === 'sensor') {
        alert.attributes.alarms = this.selectedSensors.join(',')
      }
      alert.attributes.integration = this.integration ? 'ralset' : ''
    },
    processNewAlert() {
      if (this.alertForm.selectedType === alertType.inactivity) {
        this.user.attributes.inactiveVehiclesEmail = true
        this.user.attributes.inactiveVehiclesOtherEmails = this.otherEmails
        traccar.updateUser(this.user.id, this.user
        ).then(() => {
          this.alertCreated({
            notification: {
              type: alertType.inactivity,
              always: true,
              notificators: 'mail',
              attributes: {
                otherEmails: this.otherEmails,
                name: this.name
              }
            },
            devices: []
          })
        })
      } else {
        const alert = { attributes: {}}
        if (alertType.customAlarmTypes.includes(this.alertForm.selectedType)) {
          alert.type = 'alarm'
          alert.attributes.alarms = this.alertForm.selectedType
        } else {
          alert.type = this.alertForm.selectedType
        }
        this.fillAlert(alert)
        traccar.newAlert(alert).then(this.alertCreated)
      }
    },
    handleSubmitAlertForm() {
      this.$refs.alert.validate(async valid => {
        if (valid) {
          this.loading = true
          this.$store.commit('transient/SET_LOADING', true)
          try {
            if (this.isNewAlert) {
              await this.processNewAlert()
            } else {
              await this.updateAlert()
            }
          } catch (e) {
            this.$log.error(e)
            this.$message({ type: 'error', message: e.message + ' ' + e.response && e.response.data, duration: 15000 })
          }
          this.loading = false
          this.$store.commit('transient/SET_LOADING', false)
        }
      })
    },
    clearFormData() {
      this.selectedAlert = null
      this.alertForm.selectedType = null
      this.alertForm.notificatorsGroup = []
      this.allVehicles = []
      this.selectedDevices = []
      this.selectedDevice = null
      this.maxDuration = 0
      this.otherEmails = ''
      this.integration = false
      this.timetable = {
        allWeek: true,
        startTime: '00:00',
        endTime: '23:59',
        weekDays: {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true
        }
      }
    },
    async alertCreated(_alert) {
      const devices = _alert.always ? [] : this.selectedDevices
      // Connect device <-> notification
      await this.addAllPermissions(devices, _alert)
      await this.processAlertCreatedOrUpdated(_alert)
      this.$message({
        type: 'success',
        message: this.$t('settings.alert_created')
      })
      this.clearFormData()
      await this.$store.dispatch('user/fetchAlerts')
      this.$store.commit('transient/SET_LOADING', false)
      this.loading = false
      this.isOpenAlertForm = false
    },
    async addAllPermissions(devices, _alert) {
      // deviceId -> notificationId
      await traccar.addAllPermissions(devices.map(deviceId => ({
        deviceId,
        notificationId: _alert.id
      })))
      // when alert is always we need to add the computed to all devices
      if (!devices.length) { devices = this.devices.map(d => d.id) }
      // deviceId -> attributeId (needs admin)
      await pinmeapi.addAllPermissions(devices.map(d => getComputed(d, _alert)).filter(p => p))
      // deviceId -> geofenceId
      if (_alert.attributes.geofences) {
        await traccar.addAllPermissions(devices.map(deviceId => _alert.attributes.geofences.map(geofenceId => ({
          deviceId,
          geofenceId
        }))).flat())
      }
    },
    async updateAlert() {
      if (this.alertForm.selectedType === alertType.inactivity) {
        this.user.attributes.inactiveVehiclesOtherEmails = this.otherEmails
        await traccar.updateUser(this.user.id, this.user)
      }
      const alert = this.selectedAlert.notification
      this.fillAlert(alert)
      const _alert = await traccar.updateAlert(alert.id, alert)
      if (!_alert.always) {
        const permissionsToDelete = this.selectedAlert.devices.map(v => {
          return {
            deviceId: v.id,
            notificationId: _alert.id
          }
        })
        await traccar.deleteAllPermissions(permissionsToDelete)
        const alert = this.alerts.find(a => a.notification.id === _alert.id)
        alert.devices = []
        for (const id of this.selectedDevices) {
          this.$log.debug(_alert)
          if (_alert.always === false) {
            const d = this.devices.find(d => d.id === id)
            alert.devices.push(d)
          }
        }
      }
      // Connect device <-> notification / attribute
      await this.addAllPermissions(_alert.always ? [] : this.selectedDevices, _alert)
      await this.processAlertCreatedOrUpdated(_alert)
      this.$message({
        type: 'success',
        message: this.$t('settings.alert_updated')
      })
      this.clearFormData()
      this.isOpenAlertForm = false
    },
    async processAlertCreatedOrUpdated(_alert) {
      if (_alert.type === alertType.deviceFuelDrop) {
        await this.addAttribute(_alert, 'fuelDropThreshold', 20)
      }
      if (_alert.attributes.alarms === 'alarmEngineOn') {
        await this.addAttribute(_alert, 'minTripTime', this.maxDuration)
      }
      if (_alert.attributes.alarms === 'alarmEngineOff') {
        await this.addAttribute(_alert, 'minStopTime', this.maxDuration)
      }
    },
    async addAttribute(alert, attributeName, attributeValue) {
      const promises = []
      for (const d of this.devices) {
        if (alert.always || this.selectedDevices.includes(d.id)) {
          if (d.attributes[attributeName] !== attributeValue) {
            d.attributes[attributeName] = attributeValue
            if (promises.length > 15) {
              await Promise.all(promises)
              promises.length = 0
            }
            promises.push(traccar.updateDevice(d.id, d).then(() => this.$message({
              type: 'success',
              message: d.name + ' ' + 'OK'
            })).catch(() => {
            }))
          }
        }
      }
    },
    handleEdit(row) {
      this.isNewAlert = false
      this.selectedAlert = row
      this.selectedGeofences = row.notification.attributes.geofences ? row.notification.attributes.geofences : []
      this.alertForm.selectedType = row.notification.type === 'alarm' ? row.notification.attributes.alarms : row.notification.type
      this.otherEmails = row.notification.attributes.otherEmails
      this.name = row.notification.attributes.name
      this.checkGeofences = row.notification.attributes.checkGeofences
      this.integration = row.notification.attributes.integration === 'ralset'
      this.selectedDrivers = row.notification.attributes.drivers ? row.notification.attributes.drivers : []
      if (row.notification.always) {
        this.allVehicles.push('always')
      } else {
        row.devices.forEach(d => this.selectedDevices.push(d.id))
      }

      const notificators = row.notification.notificators.split(',')
      this.alertForm.notificatorsGroup.push(...new Set(notificators))

      if (row.notification.attributes.timetable) {
        this.timetable = row.notification.attributes.timetable
      }

      this.maxDuration = row.notification.attributes.maxDuration || 0

      this.$log.debug(row)

      this.isOpenAlertForm = !this.isOpenAlertForm
    },
    handleDoubleClick(row) {
      this.handleEdit(row)
    },
    handleAllVehiclesSelect() {
      if (this.allVehicles.length === 1) {
        this.selectedDevices = []
      }
    },
    async handleDelete(row) {
      const selectedType = row.notification.type === 'alarm' ? row.notification.attributes.alarms : row.notification.type
      await this.$confirm(this.$t('settings.alert_delete_info') + this.$t('settings.alert_' + selectedType), this.$t('settings.alert_delete_title'), {
        confirmButtonText: this.$t('settings.alert_edit_confirm'),
        cancelButtonText: this.$t('settings.alert_edit_cancel')
      })
      try {
        this.$store.commit('transient/SET_LOADING', true)
        if (row.notification.type !== alertType.inactivity) {
          await traccar.deleteAlert(row.notification.id)
        } else {
          this.user.attributes.inactiveVehiclesEmail = false
          this.user.attributes.inactiveVehiclesOtherEmails = this.otherEmails
          await traccar.updateUser(this.user.id, this.user)
        }
        this.$message({
          message: this.$t('settings.alert_deleted'),
          type: 'success',
          duration: 5 * 1000
        })
        await this.$store.dispatch('user/fetchAlerts')
      } catch (e) {
        this.$message({
          message: e.message + ' ' + (e.response && e.response.data),
          type: 'error',
          duration: 10000
        })
      }
    },
    tableRowStyle() {
      return 'font-size: 14px'
    },
    tableHeaderStyle() {
      return 'font-size: 14px'
    },
    renderFunc(h, option) {
      return <span title={option.name}>{option.name}</span>
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../../styles/element-variables.scss';

  .form-item-block-left{
    display: table-cell;
    float: left;
  }
  .form-item-block-right{
    display: table-cell;
    float: right;
  }
  .form-item-block {
    width: 100%;
    display: table;
    margin-bottom: 5px
  }
  .form-item-row {
    display: table-row;
  }
  .cellInfo {
    font-size: 12px;
  }
  .alertFormButton {
    float: right;
    margin-right: 10px;
  }
  .alertSelectButton {
    float: left;
    margin-left: 10px;
    height: 40px
  }
  .alertInfo{
    margin-left: 5px;
    color: $--color-info
  }
  .inactivityAlertInfo{
    margin-left: 5px;
    margin-bottom: 15px;
    font-size: 20px;
  }
  .alertTypeTitle{
    font-size: 16px;
  }
</style>
